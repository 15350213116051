@font-face {
  font-family: 'serverless';
  src: url('./fonts/serverless-regular.woff2') format('woff2'),
    url('./fonts/serverless-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body,
div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: normal;
  background: var(--mainBg);
  font-family: 'serverless', 'HelveticaNeue-Regular', 'Helvetica Neue Regular', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  word-wrap: break-word;
  letter-spacing: 0.25px;
}

html,
body {
  background: #4c4c4c;
}
a {
  color: white;
  text-underline: none;
  text-decoration: none;
}

.text {
  color: white;
  font-size: 20px;
}

.link {
  color: white;
  font-size: 20px;
  display: contents;
}

.link:hover {
  color: #BBBBBB;
}

.container {
  background-image: url("./images/clouds_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.logo {
  background-color: rgba(255, 255, 255, 1);
  display: inline;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  pointer-events: none;
  width: 100%;
  height: auto;
  max-width: 300px;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

.photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  vert-align: top;
  height: auto;
  max-width: 300px;
  animation-duration: 2000ms;
  animation-name: fadeIn;
  border: 4px solid #fff;
  border-radius: 10px;
}

.badge {
  height: 25%;
  border: none;
}

.badge:hover {
    filter: alpha(opacity=70);
    opacity: 0.7;
}

.content {
  display: contents !important;
}

.photo:hover {
  border-color: #BBBBBB;
}

.tagline {
  align-items: center;
  height: auto;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

/**
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
